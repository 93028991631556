<template>
  <div class="gift-box">
    <my-popup title="发送礼物" :is-show="isShow" @leftHandler="toggleShowPopup(false)">
      <div class="grid-box">
        <div :class="giftIndex === 1 ? 'gift-active gift-item' : 'gift-item'" @click="selectGift(1)">
          <img src="../../../../assets/gift/gift_1.png" style="width: 100px; height: 100px" />
          <div style="line-height: 32px">玫瑰花</div>
        </div>
        <div :class="giftIndex === 2 ? 'gift-active gift-item' : 'gift-item'" @click="selectGift(2)">
          <img src="../../../../assets/gift/gift_2.png" style="width: 100px; height: 100px" />
          <div style="line-height: 32px">飞吻</div>
        </div>
        <!-- <div :class="giftIndex === 3 ? 'gift-active gift-item' : 'gift-item'" @click="selectGift(3)">
          <img src="../../../../assets/gift/gift_3.png" style="width: 100px; height: 100px" />
          <div style="line-height: 32px">风车</div>
        </div> -->
        <!-- <div :class="giftIndex === 4 ? 'gift-active gift-item' : 'gift-item'" @click="selectGift(4)">
          <img src="../../../../assets/gift/gift_4.png" style="width: 100px; height: 100px" />
          <div style="line-height: 32px">爱你哟</div>
        </div> -->
        <div :class="giftIndex === 5 ? 'gift-active gift-item' : 'gift-item'" @click="selectGift(5)">
          <img src="../../../../assets/gift/gift_5.png" style="width: 100px; height: 100px" />
          <div style="line-height: 32px">保时捷</div>
        </div>
        <div :class="giftIndex === 6 ? 'gift-active gift-item' : 'gift-item'" @click="selectGift(6)">
          <img src="../../../../assets/gift/gift_6.png" style="width: 100px; height: 100px" />
          <div style="line-height: 32px">十里桃花</div>
        </div>
        <!-- <div :class="giftIndex === 7 ? 'gift-active gift-item' : 'gift-item'" @click="selectGift(7)">
          <img src="../../../../assets/gift/gift_7.png" style="width: 100px; height: 100px" />
          <div style="line-height: 32px">星舟泛心</div>
        </div> -->
        <div :class="giftIndex === 8 ? 'gift-active gift-item' : 'gift-item'" @click="selectGift(8)">
          <img src="../../../../assets/gift/gift_8.png" style="width: 100px; height: 100px" />
          <div style="line-height: 32px">豪华游艇</div>
        </div>
      </div>
      <div class="btn-box">
        <div class="btn-bg" v-if="!!giftIndex">1</div>
        <div :class="!!giftIndex ? 'btn-gray btn-active' : 'btn-gray'" @click="sendGift">赠送</div>
      </div>
    </my-popup>
  </div>
</template>

<script>
import MyPopup from "./containers/MyPopup";
export default {
  name: "LiveLuckyBag",
  data() {
    return {
      giftIndex: void 0,
    };
  },
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    MyPopup,
  },
  methods: {
    selectGift(index) {
      this.giftIndex = index;
    },
    sendGift() {
      this.$emit("sendGift", this.giftIndex);
    },
    toggleShowPopup(state) {
      this.$emit("update:isShow", state);
    },
  },
  mounted() {},
};
</script>

<style scoped lang="scss">
.gift-box {
  box-sizing: border-box;
  width: 100%;
  .grid-box {
    box-sizing: border-box;
    padding: 30px;
    display: grid;
    grid-template-rows: 132px;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 30px;
    .gift-item {
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
    }
    .gift-active {
      border: 1px solid #ff2c56;
      border-radius: 6px;
    }
  }
  .btn-box {
    box-sizing: border-box;
    width: 100%;
    height: 100px;
    border-top: 2px solid #ff2c56;
    margin-top: 188px;
    display: flex;
    justify-content: end;
    align-items: center;
    padding: 0 30px;
    position: relative;
    font-size: 18px;
    color: #fff;
    line-height: 33px;
    .btn-gray {
      width: 100px;
      height: 33px;
      background: #4d4d53;
      text-align: center;
      border-radius: 18.5px;
      cursor: pointer;
      z-index: 999;
    }
    .btn-active {
      background: linear-gradient(90deg, #ff7f5f 0%, #ff3776 100%);
    }
    .btn-bg {
      width: 130px;
      height: 33px;
      position: absolute;
      right: 32px;
      background: #2a2d3e;
      border-radius: 18.5px;
      padding-left: 22px;
    }
  }
}
</style>

<template>
    <div class="bottom-action">
        <el-input class="input" v-model="value" placeholder="说点什么..." @keydown.enter.native="sendBulletChat"></el-input>
        <div class="actions">
            <div class="iconfont action-item" v-for="(item, index) in actions" :key="index" @click.stop="transfer(item.handler)">
                {{ item.icon }}
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "LiveBottomAction",
    data() {
        return {
            value: "",
            actions: [
                { icon: "\ue62c", handler: "livePause" },
                { icon: "\ue625", handler: "liveStop" },
                { icon: "\ue70c", handler: "luckyBag" },
                { icon: "\ue70b", handler: "gift" },
                { icon: "\ue727", handler: "goods" },
            ],
        };
    },
    computed: {},
    components: {},
    props: {},
    methods: {
        transfer(fun) {
            this.$emit("transfer", fun);
        },
        sendBulletChat() {
            this.$emit("sendBulletChat", {
                value: this.value,
                isPerson: 1,
            });
            this.value = "";
        },
    },
    mounted() {},
};
</script>

<style scoped lang="scss">
.bottom-action {
    position: relative;
    box-sizing: border-box;
    display: flex;
    z-index: 99;
    ::v-deep .input {
        width: 200px;
        height: 46px;
        input {
            height: 100%;
            padding: 0 12px;
            color: #fff;
            background-color: rgba(0, 0, 0, 0.4);
            border: none;
            border-radius: 23px;
        }
    }
    .actions {
        flex: 1;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        .action-item {
            width: 46px;
            height: 46px;
            margin-left: 22px;
            font-size: 30px;
            line-height: 46px;
            text-align: center;
            color: #999;
            border-radius: 50%;
            background-color: #fff;
            user-select: none;
            cursor: pointer;
        }
        .action-item:first-child {
            font-size: 24px;
            text-indent: 2px;
        }
        .action-item:last-child {
            color: #4b45ff;
        }
    }
}
::v-deep .input input::-webkit-input-placeholder {
    /* WebKit browsers */
    color: #fff;
}

::v-deep .input input:-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    color: #fff;
}

::v-deep .input input::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    color: #fff;
}

::v-deep .input input:-ms-input-placeholder {
    /* Internet Explorer 10+ */
    color: #fff;
}
</style>
